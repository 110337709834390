import React from 'react';
import { Link } from 'gatsby';
import Seo from '../components/Seo';
import { indexHolder } from './index.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import RisingSpans from '../components/RisingSpans';
import { motion } from 'framer-motion';

const holderVariants = {
  hidden: {},
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
};

const buttonHolderVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      ease: [0.22, 1, 0.36, 1],
      duration: 0.7,
    },
  },

  exit: {
    opacity: 0,
    y: 0,
  },
};

export default function IndexPage() {
  return (
    <motion.article className={indexHolder} variants={holderVariants}>
      {/* <div dangerouslySetInnerHTML={{ __html: content }} className="text" /> */}
      <div className="content">
        <RisingSpans>
          <h2>Boutique moving image equipment rentals for the creative industries.</h2>
        </RisingSpans>
        <motion.div variants={buttonHolderVariants}>
          <Link className="button" to="/full-inventory">
            Browse our inventory
          </Link>
        </motion.div>
      </div>
      <StaticImage src="../images/homepage/MCX Films homepage image 01.jpg" alt="MCX Films Hero Image" />
    </motion.article>
  );
}

export const Head = () => <Seo title="Welcome to MCX Films - Boutique moving image equipment rentals for the creative industries" />;
