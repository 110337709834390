import React from 'react';
import { motion } from 'framer-motion';
import { risingSpansHolder } from './RisingSpans.module.scss';

export default function RisingSpans({ children, staggerChildren = 0.1, exitAnimation, ...props }) {
  const spanVars = {
    initial: {
      opacity: 0,
      y: 50,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        ease: [0.22, 1, 0.36, 1],
        duration: 0.7,
      },
    },
    exit: { opacity: 0, y: 0 },
  };

  let sections = [];
  let words = [];
  let childClass = null;

  if (typeof children.props.children === 'string') sections = children.props.children.split(' ');
  else {
    sections = children.props.children;
  }

  if (children.props.className) {
    childClass = children.props.className;
  }
  sections.forEach((child) => {
    if (typeof child === 'string') {
      let tempWords = child.trim().split(' ');
      words.push(...tempWords);
    } else {
      words.push(child);
    }
  });

  let type = motion.div;

  switch (children.type) {
    case 'h1':
      type = motion.h1;
      break;
    case 'h2':
      type = motion.h2;
      break;
    case 'h3':
      type = motion.h3;
      break;
    case 'h4':
      type = motion.h4;
      break;
    case 'h5':
      type = motion.h5;
      break;
    case 'h6':
      type = motion.h6;
      break;
    default:
      type = motion.div;
  }

  return (
    <>
      {React.createElement(
        type,
        {
          className: `${risingSpansHolder}${childClass ? ` ${childClass}` : ''}`,
          ...props,
        },
        words.map((word, index) => {
          return (
            <div className="spanHider" key={index}>
              <motion.span variants={spanVars}>{word} </motion.span>
            </div>
          );
        })
      )}
    </>
  );
}
